/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from 'react';
import { theme } from './src/theme.ts';
import { ThemeProvider } from '@emotion/react';
import 'typeface-dosis';

export const wrapPageElement = ({ element, props }) => {
  return <ThemeProvider theme={theme}>
    {element}
  </ThemeProvider>
}