import facepaint from 'facepaint'


export const mq = facepaint([
  '@media(min-width: 560px)',
  '@media(min-width: 920px)',
  '@media(min-width: 1120px)'
]);

export const theme: Emotion.Theme = {
  fontSize:{
    headerSmall: 24,
    headerMedium: 36,
    headerRegular: 48,
    headerLarge: 60,
    buttonText: 18,
    
    textSmall: [12, 12, 12],
    textRegular: [14, 16, 16],
    textMedium: [16, 18, 18],
    textLarge: [20, 22, 24],
    textExtraLarge: [36, 42, 48],
  },
  colors: {
    primary: '#155d8f',
    primary_accent: '#BCECE8',
    black: '#2F3030',
    white: '#FFFFFF',
    white_accent: '#F0F4F7',
    footer: '#1D2D38',
    footer_text: '#A2B0B9',
    product_colors: {
      start: '#fc7413',
      max: '#fd9722',
      giga: '#faa825'
    }
  }
};